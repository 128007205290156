<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <home msg="Welcome to Your App"/> -->
    <router-view />
  </div>
</template>

<script>
// import home from './views/home.vue'

export default {
  name: 'App',
  // components: {
  //   home
  // }
}
</script>

<style>
body {
  background: #f2f8ff;
  position: relative;
  margin: 0;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
