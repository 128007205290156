import VueRouter from "vue-router";

const router = new VueRouter({
  routes: [
    {
      // 首页
      path: "/",
      name: "home",
      component: () =>
        import("../views/home.vue"),
      meta: {
        title: "首页",
      },
    },
    {
      // 课程专区
      path: "/course",
      name: "course",
      component: () =>
        import("../views/course.vue"),
      meta: {
        title: "课程专区",
      },
    },
    {
      // 课程专区
      path: "/courseDetail",
      name: "courseDetail",
      component: () =>
        import("../views/courseDetail.vue"),
      meta: {
        title: "视频详情",
      },
    },
    {
      // 实验专区
      path: "/lab",
      name: "lab",
      component: () =>
        import("../views/lab.vue"),
      meta: {
        title: "实验专区",
      },
    },
    {
      // 实验专区
      path: "/labDetail",
      name: "labDetail",
      component: () =>
        import("../views/labDetail.vue"),
      meta: {
        title: "查看详情",
      },
    },
    {
      // 评估专区
      path: "/rate",
      name: "rate",
      component: () =>
        import("../views/rate.vue"),
      meta: {
        title: "评估专区",
      },
    },
    {
      // 评估专区
      path: "/rateDetail",
      name: "rateDetail",
      component: () =>
        import("../views/rateDetail.vue"),
      meta: {
        title: "查看详情",
      },
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      // 如果浏览器有保存的位置信息，则在切换时恢复到保存的位置
      return savedPosition;
    } else {
      // 没有保存的位置信息时，滚动到页面顶部
      return { x: 0, y: 0 };
    }
  }
})
export default router;
